<template>
  <div class="swiperContainer">
    <!-- <vue-ellipse-progress :progress="Number(flow_datas[5])" :size="96" thickness="5" dot="9" fontSize="1.4rem" :color="gradient">
        <span slot="legend-value"></span>
        <p slot="legend-caption">배터리</p>
    </vue-ellipse-progress>
    <div class="inspectionTitle">
        <p class="title">점검현황</p>
        <p class="subtitle">배터리 교체 및 수신여부</p>
    </div>
    <vue-ellipse-progress :progress="Number(flow_datas[1])" :size="96" thickness="5" dot="9" fontSize="1.4rem" :color="gradient">
        <span slot="legend-value"></span>
        <p slot="legend-caption">미수신</p>
    </vue-ellipse-progress> -->

    <h4 class="title1">설치위치</h4>
    <div class="subtitle2">{{ address_ }}</div>
    <!-- <div class="subtitle2"><svg xmlns="http://www.w3.org/2000/svg" height="20" width="17.5" viewBox="0 0 448 600"><path d="M128 0c13.3 0 24 10.7 24 24l0 40 144 0 0-40c0-13.3 10.7-24 24-24s24 10.7 24 24l0 40 40 0c35.3 0 64 28.7 64 64l0 16 0 48 0 256c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 192l0-48 0-16C0 92.7 28.7 64 64 64l40 0 0-40c0-13.3 10.7-24 24-24zM400 192L48 192l0 256c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-256zM329 297L217 409c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47 95-95c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg> 이벤트발생시 Device화면 강조 표시 &nbsp;&nbsp;<span>예정</span></div>
    <div class="subtitle2"><svg xmlns="http://www.w3.org/2000/svg" height="20" width="17.5" viewBox="0 0 448 600"><path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg> UI 변경 &nbsp;&nbsp;<span>2024-12-04</span></div> -->
  </div>
</template>

<script>
import { VueEllipseProgress } from 'vue-ellipse-progress'
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.min.css";

import EventBus from '@/services/EventBus'

export default {
    components: {
        VueEllipseProgress,
        swiper,
        swiperSlide,
    },
    created: function() {
        this.userInfo = this.$store.state.auth.user_info;
        this.user = this.userInfo.user;
        this.sites = this.userInfo.sites

        this.active_site_index = _.get(this.user, "active_site_index", 0);
        //this.active_site_name = this.user.active_site_name;

        this.getWeatherData(this.active_site_index, this.user.active_site_name);
    },
    // mounted() {
    //     EventBus.$on("send", (data) => {
    //         console.log("Data : ", data)
    //         this.siteName = data.site
    //         this.getWeatherData(data.site_index, data.site)
    //     })
    // },
    // beforeDestroy(){
    //     EventBus.$off("send")
    // },
    data() {
        return {
            active_site_index: 0,
            siteWeather: {},
            weather_image: 'images/cloud.png',
            address_ : "",
            swiperOption: {
                autoplay: {
                    delay: 6000
                },
                spaceBetween : 80,
                slidesPerView: 1,
                centeredSlides: true,
            },
            dens_datas: [0,0,0,0,0,0,0,0,0,0,0,0],
            temp_datas: [0,0,0,0,0,0,0,0,0,0,0,0],
            flow_datas: [0,2,0,0,5,30,0,0,0,0,0,0],
            greenColor: {},
            gradient: {
                radial: false,
                colors: [
                {
                    color: '#6546f7',
                    offset: "0",
                    opacity: '1',
                },
                {
                    color: 'lime',
                    offset: "100",
                    opacity: '0.6',
                },
                ]
            }
        }
    },
    methods: {
        getWeatherData(index, address) {
            var self = this;
            var default_site_guid = _.get(this.user, "default_site_guid");
            this.address_ = address;
        },
        sensorValue() {
            let value = this.device.sensor.data
            let arr1 = value[0].value
            let arr2 = value[1].value
            let arr3 = value[2].value
            //let data = arr1.concat(arr2, arr3)
            //let result = this.splitIntoChunk(data, 3)
            this.temp_datas = arr1;
            this.dens_datas = arr2;
            this.flow_datas = arr3;
            //console.log("result : ", this.dens_datas)

        },
    }
}
</script>

<style scoped lang="scss">
@font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: 'GmarketSansMedium', sans-serif;
}

.swiperContainer {
    position: relative;
    padding: 0.6%;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    .inspectionTitle {
        text-align: center;
        margin: 2%;
        .title {
            font-size: 22px;
        }
        .subtitle {
            font-size: 10px;
        }

    }
}

.title1 {
    margin-top: 5%;
    font-size: 23px;
    font-weight: bold;
}
.subtitle2 {
    text-align: center;
    margin: 5%;
    font-size: 15px;
    position: relative;
    span {
        font-weight: bold;
        font-size: 10.5px;
    }
}
</style>